// Fonts
@import url('https://fonts.googleapis.com/css?family=Nunito');

// Variables
@import 'variables';

// Layout
@import '_layout';

// Bootstrap
@import '~bootstrap/scss/bootstrap';



/*
|--------------------------------------------------------------------------
| Expired Page
|--------------------------------------------------------------------------
*/

@media screen and (min-resolution: 120dpi) {
body {zoom: 0.8;}
}

.expired-text {
  margin-top: 100px;

  font-size: 1.2rem;

  a {
    color: #fff;
    text-decoration: underline;
  }
}

.card-text.price {
  font-size: 0.8rem !important;
}

.ko-nummer {
  display: flex;
  width:110px;
  height: 110px;
  margin: 30px auto 30px auto;
  background: #DBD2C3;
  color: #000;
  justify-content: center;
  align-items: center;
  border-radius: 26px;
}

  .number {
    font-size: 40px;
    font-weight: bold;
    color: #000;
  }
